<template>
    <stagger-fade-horizontal-animation
        :reverse="reverse"
        :trigger="() => $refs.section"
        :elements="[
            {
                element: () => $refs.imageColumn,
            },
            {
                element: () => $refs.textColumn,
            },
        ]"
    >
        <section
            class="horizontal-image-text-cta"
            :class="{ reverse }"
            ref="section"
        >
            <b-container>
                <b-row class="content-row">
                    <b-col lg="6" class="image-column" ref="imageColumn">
                        <div class="image-container">
                            <lazy-image
                                class="content-image"
                                :src="image.src"
                                :alt="image.alt"
                                :width="image.width"
                                :height="image.height"
                                v-test:image
                            />
                        </div>
                    </b-col>
                    <b-col lg="6" ref="textColumn">
                        <mobile-gutter>
                            <div class="content-container">
                                <h2 class="title h3" v-test:title>
                                    {{ title }}
                                </h2>

                                <markdown-content
                                    class="description"
                                    :content="description"
                                    v-test:description
                                />

                                <div class="buttons">
                                    <div
                                        v-if="(button.to || button.href) && button.name"
                                        class="button"
                                    >
                                        <b-button
                                            class="button"
                                            :to="button.to"
                                            :href="button.href"
                                            variant="primary"
                                            v-test:button
                                        >
                                            {{ button.name }}
                                        </b-button>
                                    </div>

                                    <div
                                        v-if="(secondaryButton.to || secondaryButton.href) && secondaryButton.name"
                                        class="button"
                                    >
                                        <b-button
                                            class="button"
                                            :to="secondaryButton.to"
                                            :href="secondaryButton.href"
                                            variant="primary"
                                            v-test:secondaryButton
                                        >
                                            {{ secondaryButton.name }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </mobile-gutter>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </stagger-fade-horizontal-animation>
</template>

<script>
    import MarkdownContent from '../../utils/MarkdownContent/MarkdownContent';

    export default {
        name: 'horizontal-image-text-cta',

        components: { MarkdownContent },

        props: {
            image: {
                type: Object,
                default: () => ({}),
            },

            title: {
                type: String,
                default: '',
            },

            description: {
                type: String,
                default: '',
            },

            button: {
                type: Object,
                default: () => ({}),
            },

            secondaryButton: {
                type: Object,
                default: () => ({}),
            },

            reverse: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    $desktop-breakpoint: lg;

    .horizontal-image-text-cta {
        padding: 30px 0;
        overflow-x: hidden;

        @include media-breakpoint-up($desktop-breakpoint) {
            padding: 25px 0;
        }
    }

    .reverse {
        .row {
            @include media-breakpoint-up($desktop-breakpoint) {
                flex-direction: row-reverse;
            }
        }

        .image-column {
            @include media-breakpoint-up($desktop-breakpoint) {
                display: flex;
                justify-content: center;
            }
        }

        .image-container {
            @include media-breakpoint-up($desktop-breakpoint) {
                padding-left: 0;
                padding-right: 65px;
            }
        }

        .content-container {
            @include media-breakpoint-up($desktop-breakpoint) {
                padding-right: 0;
                padding-left: 65px;
            }
        }
    }

    .content-row {
        align-items: center;
        flex-direction: column-reverse;

        @include media-breakpoint-up($desktop-breakpoint) {
            flex-direction: unset;
        }
    }

    .image-column {
        text-align: center;
    }

    .image-container {
        text-align: center;

        @include media-breakpoint-up($desktop-breakpoint) {
            padding-left: 65px;
        }
    }

    img {
        max-width: 100%;
        height: auto;

        @include media-breakpoint-up($desktop-breakpoint) {
            width: auto;
            max-width: 100%;
        }
    }

    .content-container {
        text-align: center;
        margin-bottom: 15px;

        @include media-breakpoint-up($desktop-breakpoint) {
            margin-top: 0;
            text-align: left;
            padding-right: 65px;
        }
    }

    .title {
        font-weight: bold;

        @include media-breakpoint-up($desktop-breakpoint) {
            text-align: left;
        }
    }

    .description {
        margin-bottom: 15px;

        @include media-breakpoint-up($desktop-breakpoint) {
            margin-bottom: 25px;
            font-size: rem(20px);
        }

        ::v-deep {
            p:not(:last-child) {
                margin-bottom: 1.5em;

                @include media-breakpoint-up($desktop-breakpoint) {
                    margin-bottom: 2.5em;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }
        }

        ::v-deep :last-child {
            margin-bottom: 0;
        }
    }

    .buttons {
        margin: -5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up($desktop-breakpoint) {
            justify-content: flex-start;
        }

        > * {
            padding: 5px;
        }
    }
</style>
